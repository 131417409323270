import React from "react"
import Escalas from "../components/escalas/Escalas"

const EscalasPage = () => {

  return (
    <React.Fragment>
        <Escalas/>
    </React.Fragment>
  )
}
 
export default EscalasPage
