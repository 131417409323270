import React from "react"
import Doacao from "../components/doacao/Doacao"

const DoacaoPage = () => {

  return (
    <React.Fragment>
        <Doacao/>
    </React.Fragment>
  )
}
 
export default DoacaoPage
