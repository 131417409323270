import React from "react";
import Error404 from "../components/geral/Error404";

const Error404Page = () => {

  return (
    <React.Fragment>
        <Error404/>
    </React.Fragment>
  )
}
 
export default Error404Page
