import React from "react";
import CentroObstetrico from "../../components/escalas/pdfs-escala/CentroObstetrico";

const CentroObstetricoPage = () => {

  return (
    <React.Fragment>
        <CentroObstetrico/>
    </React.Fragment>
  )
}
 
export default CentroObstetricoPage
