import React from "react";
import RotinaAlojamentoConjunto from "../../components/escalas/pdfs-escala/RotinaAlojamentoConjunto";

const RotinaAlojamentoConjuntoPage = () => {

  return (
    <React.Fragment>
        <RotinaAlojamentoConjunto/>
    </React.Fragment>
  )
}
 
export default RotinaAlojamentoConjuntoPage
