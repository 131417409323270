import React from "react";
import RotinaPediatricaMaternidade from "../../components/escalas/pdfs-escala/RotinaPediatricaMaternidade";

const RotinaPediatricaMaternidadePage = () => {

  return (
    <React.Fragment>
        <RotinaPediatricaMaternidade/>
    </React.Fragment>
  )
}
 
export default RotinaPediatricaMaternidadePage
