import React from "react";
import UtiNeo from "../../components/escalas/pdfs-escala/UtiNeo";

const UtiNeoPage = () => {

  return (
    <React.Fragment>
        <UtiNeo/>
    </React.Fragment>
  )
}
 
export default UtiNeoPage
