import React from "react";
import RotinaOncologica from "../../components/escalas/pdfs-escala/RotinaOncologica";

const RotinaOncologicaPage = () => {

  return (
    <React.Fragment>
        <RotinaOncologica/>
    </React.Fragment>
  )
}
 
export default RotinaOncologicaPage
