import React from "react";
import Psiquiatria from "../../components/escalas/pdfs-escala/Psiquiatria";

const PsiquiatriaPage = () => {

  return (
    <React.Fragment>
        <Psiquiatria/>
    </React.Fragment>
  )
}
 
export default PsiquiatriaPage
