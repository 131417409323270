import React from "react";
import RotinaCirurgica from "../../components/escalas/pdfs-escala/RotinaCirurgica";

const RotinaCirurgicaPage = () => {

  return (
    <React.Fragment>
        <RotinaCirurgica/>
    </React.Fragment>
  )
}
 
export default RotinaCirurgicaPage
