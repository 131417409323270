import React from "react";
import UtiAdulto from "../../components/escalas/pdfs-escala/UtiAdulto";

const UtiAdultoPage = () => {

  return (
    <React.Fragment>
        <UtiAdulto/>
    </React.Fragment>
  )
}
 
export default UtiAdultoPage
