import React from "react";
import ClinicoGeralAssistente from "../../components/escalas/pdfs-escala/ClinicoGeralAssistente";

const ClinicoGeralAssistentePage = () => {

  return (
    <React.Fragment>
        <ClinicoGeralAssistente/>
    </React.Fragment>
  )
}
 
export default ClinicoGeralAssistentePage
