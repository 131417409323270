import React from "react";
import PoliAdulto from "../../components/escalas/pdfs-escala/PoliAdulto";

const PoliAdultoPage = () => {

  return (
    <React.Fragment>
        <PoliAdulto/>
    </React.Fragment>
  )
}
 
export default PoliAdultoPage
