import React from "react";
import ProntoSocorro from "../../components/escalas/pdfs-escala/ProntoSocorro";

const ProntoSocorroPage = () => {

  return (
    <React.Fragment>
        <ProntoSocorro/>
    </React.Fragment>
  )
}
 
export default ProntoSocorroPage