import React from "react";
import RotineirosClinicas from "../../components/escalas/pdfs-escala/RotineirosClinicas";

const RotineirosClinicasPage = () => {

  return (
    <React.Fragment>
        <RotineirosClinicas/>
    </React.Fragment>
  )
}
 
export default RotineirosClinicasPage
