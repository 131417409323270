import React from "react";
import ProntoSocorroPediatrico from "../../components/escalas/pdfs-escala/ProntoSocorroPediatrico";

const ProntoSocorroPediatricoPage = () => {

  return (
    <React.Fragment>
        <ProntoSocorroPediatrico/>
    </React.Fragment>
  )
}
 
export default ProntoSocorroPediatricoPage
