import React from "react"
import Ouvidoria from "../components/ouvidoria/Ouvidoria"

const OuvidoriaPage = () => {

  return (
    <React.Fragment>
        <Ouvidoria/>
    </React.Fragment>
  )
}
 
export default OuvidoriaPage
