import React from "react"
import Fundacao from "../components/fundacao/Fundacao"

const FundacaoPage = () => {

  return (
    <React.Fragment>
        <Fundacao/>
    </React.Fragment>
  )
}
 
export default FundacaoPage
