import React from "react";
import Traumato from "../../components/escalas/pdfs-escala/Traumato";

const TraumatoPage = () => {

  return (
    <React.Fragment>
        <Traumato/>
    </React.Fragment>
  )
}
 
export default TraumatoPage
