import React from "react";
import CentroCirurgico from "../../components/escalas/pdfs-escala/CentroCirurgico";

const CentroCirurgicoPage = () => {

  return (
    <React.Fragment>
        <CentroCirurgico/>
    </React.Fragment>
  )
}
 
export default CentroCirurgicoPage
