import React from "react";
import UtiAdulto2 from "../../components/escalas/pdfs-escala/UtiAdulto2";

const UtiAdulto2Page = () => {

  return (
    <React.Fragment>
        <UtiAdulto2/>
    </React.Fragment>
  )
}
 
export default UtiAdulto2Page
