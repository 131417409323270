import React from "react";
import CirurgiaoGeral from "../../components/escalas/pdfs-escala/CirurgiaoGeral";

const CirurgiaoGeralPage = () => {

  return (
    <React.Fragment>
        <CirurgiaoGeral/>
    </React.Fragment>
  )
}
 
export default CirurgiaoGeralPage
