import React from "react";
import Maternidade from "../../components/escalas/pdfs-escala/Maternidade";

const MaternidadePage = () => {

  return (
    <React.Fragment>
        <Maternidade/>
    </React.Fragment>
  )
}
 
export default MaternidadePage
