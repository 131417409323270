import React from "react"
import Home from "../components/home/Home"

const HomePage = () => {

  return (
    <React.Fragment>
        <Home/>
    </React.Fragment>
  )
}
 
export default HomePage
