import React from "react";
import Anestesistas from "../../components/escalas/pdfs-escala/Anestesistas";

const AnestesistasPage = () => {

  return (
    <React.Fragment>
        <Anestesistas/>
    </React.Fragment>
  )
}
 
export default AnestesistasPage
