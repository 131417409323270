import React from "react";
import Neurocirurgioes from "../../components/escalas/pdfs-escala/Neurocirurgioes";

const NeurocirurgioesPage = () => {

  return (
    <React.Fragment>
        <Neurocirurgioes/>
    </React.Fragment>
  )
}
 
export default NeurocirurgioesPage
