import React from "react";
import PoliticaPrivacidade from "../components/geral/PoliticaPrivacidade";

const PoliticaPrivacidadePage = () => {

  return (
    <React.Fragment>
        <PoliticaPrivacidade/>
    </React.Fragment>
  )
}
 
export default PoliticaPrivacidadePage
